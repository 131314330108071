import { lazy, Suspense } from "react";
import HomeIcon from "@mui/icons-material/Home";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CourseIcon from "@mui/icons-material/Book";
import BatchIcon from "@mui/icons-material/BatchPrediction";
import ContactIcon from "@mui/icons-material/ContactPage";
import LoginIcon from "@mui/icons-material/Login";
import EnquiryIcon from "@mui/icons-material/Help";
import DisclaimerIcon from "@mui/icons-material/Help";
import PrivacyPolicyIcon from "@mui/icons-material/Policy";
import TermAndConditionIcon from "@mui/icons-material/FactCheck";
import AboutIcon from "@mui/icons-material/Help";
import Loading from "../../components/preloader/Loading";
import { ComponentType, LazyExoticComponent } from "react";
// Lazy load components

const Home = lazy(() => import("../../features/frontend/home/Home"));
const TermAndCondition = lazy(
  () => import("../../features/frontend/terms-and-conditions/TermAndCondition")
);
const PrivacyPolicy = lazy(
  () => import("../../features/frontend/privacy-policy/PrivacyPolicy")
);
const Disclaimer = lazy(
  () => import("../../features/frontend/disclaimer/Disclaimer")
);
const Feedback = lazy(
  () => import("../../features/frontend/feedback-listing/FeedbackListing")
);
const Contact = lazy(() => import("../../features/frontend/contact/Contact"));
const CourseListing = lazy(
  () => import("../../features/frontend/course-listing/CourseListing")
);
const BatchListing = lazy(
  () => import("../../features/frontend/batch-listing/BatchListing")
);
const Login = lazy(() => import("../../features/frontend/auth/Login"));
const Enquiry = lazy(() => import("../../features/frontend/enquiry/Enquiry"));
const About = lazy(() => import("../../features/frontend/about/About"));
const ForgotPassword = lazy(
  () => import("../../features/frontend/auth/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("../../features/frontend/auth/ResetPassword")
);

export default [
  {
    label: "Home",
    component: (
      <Suspense fallback={<Loading message="Loading Home..." />}>
        <Home />
      </Suspense>
    ),
    icon: <HomeIcon />,
    path: "",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Courses",
    component: (
      <Suspense fallback={<Loading message="Loading Courses..." />}>
        <CourseListing />
      </Suspense>
    ),
    icon: <CourseIcon />,
    path: "courses",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Batches",
    component: (
      <Suspense fallback={<Loading message="Loading Batches..." />}>
        <BatchListing />
      </Suspense>
    ),
    icon: <BatchIcon />,
    path: "batches",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Feedbacks",
    component: (
      <Suspense fallback={<Loading message="Loading Feedbacks..." />}>
        <Feedback />
      </Suspense>
    ),
    icon: <FeedbackIcon />,
    path: "feedbacks",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Enquiry",
    component: (
      <Suspense fallback={<Loading message="Loading Enquiry..." />}>
        <Enquiry />
      </Suspense>
    ),
    icon: <EnquiryIcon />,
    path: "enquiry",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Contact",
    component: (
      <Suspense fallback={<Loading message="Loading Contact..." />}>
        <Contact />
      </Suspense>
    ),
    icon: <ContactIcon />,
    path: "contact",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Login",
    component: (
      <Suspense fallback={<Loading message="Loading Login..." />}>
        <Login />
      </Suspense>
    ),
    icon: <LoginIcon />,
    path: "login",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "Disclaimer",
    component: (
      <Suspense fallback={<Loading message="Loading Disclaimer..." />}>
        <Disclaimer />
      </Suspense>
    ),
    icon: <DisclaimerIcon />,
    path: "disclaimer",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "PrivacyPolicy",
    component: (
      <Suspense fallback={<Loading message="Loading Privacy Policy..." />}>
        <PrivacyPolicy />
      </Suspense>
    ),
    icon: <PrivacyPolicyIcon />,
    path: "privacypolicy",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "TermAndCondition",
    component: (
      <Suspense
        fallback={<Loading message="Loading Terms and Conditions..." />}
      >
        <TermAndCondition />
      </Suspense>
    ),
    icon: <TermAndConditionIcon />,
    path: "term-and-condition",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "About Us",
    component: (
      <Suspense fallback={<Loading message="Loading About Us..." />}>
        <About />
      </Suspense>
    ),
    icon: <AboutIcon />,
    path: "about",
    isAuthenticated: "all",
    showInMainMenu: false,
  },
  {
    label: "Forgot Password",
    component: (
      <Suspense fallback={<Loading message="Loading About Us..." />}>
        <ForgotPassword />
      </Suspense>
    ),
    icon: <AboutIcon />,
    path: "forgot-password",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "Reset Password",
    component: (
      <Suspense fallback={<Loading message="Loading reset password..." />}>
        <ResetPassword />
      </Suspense>
    ),
    icon: <AboutIcon />,
    path: "reset-password/:token",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
];
