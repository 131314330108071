import React, { useState, useEffect } from "react";

import  Box  from "@mui/material/Box";
import Sidebar from "../../components/sidebar/Sidebar";
import Footer from "./Footer";
import Loading from "../../components/preloader/Loading";



interface IBlankLayoutProps {}

const BlankLayout: React.FunctionComponent<IBlankLayoutProps> = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => setLoading(false), 1000); 
  }, []);
  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    
    {loading ? (
      <Loading message="Please wait, content is loading..." />
    ) : (
      <Sidebar /> // Main content after loading
    )}
    <Footer />
  </Box>
    </>
  );
};

export default BlankLayout;
